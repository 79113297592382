/* ---------------------------------------------------------------
 * Action Types
 */

const INIT_KISYOKEN_SETTING = 'INIT_KISYOKEN_SETTING' as const;

export const KiSyokenSettingActionTypes = {
  INIT_KISYOKEN_SETTING,
};

/* ---------------------------------------------------------------
 * Action Creators
 */

/**
 * 初回リクエスト
 */
function initKiSyokenSettingAction() {
  return {
    type: INIT_KISYOKEN_SETTING,
  };
}

export const KiSyokenSettingActionCreators = {
  initKiSyokenSettingAction,
};

/* ---------------------------------------------------------------
 * Actions
 */

type InitKiSyokenSettingAction = ReturnType<typeof initKiSyokenSettingAction>;

type KiSyokenSettingActions = InitKiSyokenSettingAction;

/* ---------------------------------------------------------------
 * State
 */

// Todo: 型定義を修正
type KiSyokenSettingState = null;

// Todo: 型定義を修正
const initialState: KiSyokenSettingState = null;

/* ---------------------------------------------------------------
 * Selector
 */

/* ---------------------------------------------------------------
 * Reducer
 */

export function kiSyokenSettingReducer(
  state: KiSyokenSettingState = initialState,
  action: KiSyokenSettingActions
): KiSyokenSettingState {
  switch (action.type) {
    default:
      return state;
  }
}
