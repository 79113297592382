/**
 * pathからGAで使用するタイトルを生成する
 */
export const pathToPageName = (path: string | undefined) => {
  switch (path) {
    case '/':
      return 'ホーム';
    case '/report/chain':
      return 'チェーン店レポート 種別';
    case '/report/chain/ki':
      return 'チェーン店レポート 新台/メイン機種';
    case '/report/hall':
      return '店舗レポート 種別';
    case '/report/hall/mks':
      return '店舗レポート 商圏';
    case '/report/hall/ki':
      return '店舗レポート 新台/メイン機種';
    case '/report/planProgressTransition':
      return '予定進捗推移';
    case '/report/ppmShare':
      return 'PPMシェア一覧';
    case '/report/depreciation':
      return '機種別償却';
    case '/report/model':
      return '機種集計';
    case '/report/sis':
      return 'SIS機種レポート';
    case '/report/dai':
      return '台別データ一覧';
    case '/report/transitionAfterIntroduction':
      return '導入後推移';
    case '/report/terminalTransition':
      return '期間推移';
    case '/report/modelTransition':
      return '機種別推移';
    case '/report/mode':
      return 'モード別集計';
    case '/report/kiSyoken':
      return '機種別商圏データ';
    case '/settings/commonSetting':
      return '共通設定';
    case '/settings/hall':
      return '店舗設定';
    case '/settings/shu-group':
      return '種別グループ設定';
    case '/settings/kiTags':
      return '機種タグ設定';
    case '/settings/dataDeletion':
      return '営業データ削除';
    case '/settings/plan':
      return '予定登録';
    case '/settings/daiCosts':
      return '機械代金登録';
    case '/settings/ki-link':
      return '機種統一設定';
    case '/settings/user':
      return 'アカウント設定';
    case '/settings/event':
      return '操作履歴';
    case '/settings/expense':
      return '経費登録';
    case '/settings/modes':
      return 'モード登録';
    case '/settings/unit-model':
      return '台番機種設定';

    // すでに削除済みのページ
    case '/settings/p-sensor':
      return 'P-SENSORリンク設定';

    default:
      return path;
  }
};
