import { createSelector } from 'reselect';

import { LoadingState } from '../../domain/schemas';

import { RootState } from '../../store';

/* ---------------------------------------------------------------
 * Action Types
 */

const FETCH_SETTINGS_OPTIONS_KISYOKEN = 'FETCH_SETTINGS_OPTIONS_KISYOKEN' as const;
const FETCH_SETTINGS_OPTIONS_KISYOKEN_REQUEST = 'FETCH_SETTINGS_OPTIONS_KISYOKEN_REQUEST' as const;
const FETCH_SETTINGS_OPTIONS_KISYOKEN_SUCCESS = 'FETCH_SETTINGS_OPTIONS_KISYOKEN_SUCCESS' as const;
const RENEW_SETTINGS_OPTIONS_KISYOKEN = 'RENEW_SETTINGS_OPTIONS_KISYOKEN' as const;

export const SettingsOptionsKiSyokenActionTypes = {
  FETCH_SETTINGS_OPTIONS_KISYOKEN,
  FETCH_SETTINGS_OPTIONS_KISYOKEN_REQUEST,
  FETCH_SETTINGS_OPTIONS_KISYOKEN_SUCCESS,
  RENEW_SETTINGS_OPTIONS_KISYOKEN,
};

/* ---------------------------------------------------------------
 * Action Creators
 */

function fetchSettingsOptionsKiSyokenAction() {
  return {
    type: FETCH_SETTINGS_OPTIONS_KISYOKEN,
  };
}

function fetchSettingsOptionsKiSyokenRequestAction() {
  return {
    type: FETCH_SETTINGS_OPTIONS_KISYOKEN_REQUEST,
  };
}

function fetchSettingsOptionsKiSyokenSuccessAction(
  settingsOptionsKiSyoken: null // Todo: 型定義を修正
) {
  return {
    type: FETCH_SETTINGS_OPTIONS_KISYOKEN_SUCCESS,
    payload: { settingsOptionsKiSyoken },
  };
}

function renewSettingsOptionsKiSyokenAction() {
  return {
    type: RENEW_SETTINGS_OPTIONS_KISYOKEN,
  };
}

export const SettingsOptionsKiSyokenActionCreators = {
  fetchSettingsOptionsKiSyokenAction,
  fetchSettingsOptionsKiSyokenRequestAction,
  fetchSettingsOptionsKiSyokenSuccessAction,
  renewSettingsOptionsKiSyokenAction,
};

/* ---------------------------------------------------------------
 * Actions
 */

type FetchSettingsOptionsKiSyokenAction = ReturnType<
  typeof fetchSettingsOptionsKiSyokenAction
>;
type FetchSettingsOptionsKiSyokenRequestAction = ReturnType<
  typeof fetchSettingsOptionsKiSyokenRequestAction
>;
type FetchSettingsOptionsKiSyokenSuccessAction = ReturnType<
  typeof fetchSettingsOptionsKiSyokenSuccessAction
>;
type RenewSettingsOptionsKiSyokenAction = ReturnType<
  typeof renewSettingsOptionsKiSyokenAction
>;

type SettingsOptionsKiSyokenAction =
  | FetchSettingsOptionsKiSyokenAction
  | FetchSettingsOptionsKiSyokenRequestAction
  | FetchSettingsOptionsKiSyokenSuccessAction
  | RenewSettingsOptionsKiSyokenAction;

/* ---------------------------------------------------------------
 * State
 */

type SettingsOptionsKiSyokenState = {
  loadingState: LoadingState;
  settingsOptionsKiSyoken?: null; // Todo: 型定義を修正
};

const initialState: SettingsOptionsKiSyokenState = {
  loadingState: 'prepare',
  settingsOptionsKiSyoken: undefined,
};

/* ---------------------------------------------------------------
 * Selector
 */

/**
 * [機種別商圏] settingsOptionsの全ての状態を取得する
 */
function settingsOptionsKiSyokenSelector(state: RootState) {
  return state.settingsOptionsKiSyoken;
}

/**
 * [機種別商圏] settingsOptionsのローディング状態を取得する
 */
export const settingsOptionsKiSyokenLoadingStateSelector = createSelector(
  settingsOptionsKiSyokenSelector,
  ({ loadingState }) => loadingState
);

/**
 * [機種別商圏] settingsOptionsのデータを取得する
 */
export const settingsOptionsKiSyokenSearchConditionSelector = createSelector(
  settingsOptionsKiSyokenSelector,
  ({ settingsOptionsKiSyoken }) => settingsOptionsKiSyoken
);

/* ---------------------------------------------------------------
 * Reducer
 */

export function settingsOptionsKiSyokenReducer(
  state = initialState,
  action: SettingsOptionsKiSyokenAction
): SettingsOptionsKiSyokenState {
  switch (action.type) {
    case FETCH_SETTINGS_OPTIONS_KISYOKEN_REQUEST:
      return {
        ...state,
        loadingState: 'loading',
      };
    case FETCH_SETTINGS_OPTIONS_KISYOKEN_SUCCESS:
      return {
        ...state,
        loadingState: 'loaded',
        settingsOptionsKiSyoken: action.payload.settingsOptionsKiSyoken,
      };
    case RENEW_SETTINGS_OPTIONS_KISYOKEN:
      return initialState;
    default:
      return state;
  }
}
